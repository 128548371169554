<template>
  <ak-container card :bodyStyle="{padding: 0}" class="ak-card-table">
    <a-card style="width: 96%; margin: 10px auto;margin-bottom: 20px">
      <a-form-model ref="form" class="ak-query-filter" :model="queryFilters" :colon="false">
        <a-row :gutter="24">
        <a-col v-bind="$wrapperThreeCol">
          <a-form-model-item label="手机号" prop="phone">
            <a-input v-model="queryFilters.phone" placeholder="请输入" />
          </a-form-model-item>
        </a-col>

        <a-col v-bind="$wrapperThreeCol">
          <a-form-model-item label="用户昵称" prop="nickname">
            <a-input v-model="queryFilters.nickname" placeholder="请输入" />
          </a-form-model-item>
        </a-col>

        <a-col v-bind="$wrapperThreeCol">
          <a-form-model-item label="用户性别" prop="sex">
            <ak-select :loadData="$const.SEX" allowClear v-model="queryFilters.sex" placeholder="请选择" @change="handleSex" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col v-bind="$wrapperThreeCol">
          <a-form-model-item label="状态" prop="state">
            <ak-select :loadData="$const.STATUS" allowClear v-model="queryFilters.state" placeholder="请选择" />
          </a-form-model-item>
        </a-col>

        <a-col v-bind="$wrapperCol">
          <a-form-model-item label="注册时间" prop="date">
            <a-range-picker v-model="queryFilters.date" show-time class="W100" :placeholder="['请选择','请选择']" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
          </a-form-model-item>
        </a-col>

        <a-col v-bind="$wrapperCol" class="action-btn-main">
          <a-button type="primary" class="ML12" @click="search">查询</a-button>
          <a-button class="ML12" @click="onResetForm">重置</a-button>
        </a-col>
      </a-row>
    </a-form-model>
    </a-card>

    <a-table :pagination='pagination'
              :columns="columns"
              :data-source="tableList"
              :scroll="{ x: 100 }"
              :rowKey="(record) => record.id"
              @change="handleTableChange"
              :loading="loading"
              style="width:96%; margin: 0 auto"
      >
      <template #headImage="text">
        <img :src="text" class="headImage" alt="">
      </template>
      <template #state="text">
        <span v-if="text == 1">正常</span>
        <span v-else class="redColor">禁用</span>
      </template>

      <!--性别 -->
      <template #sex="text">
        <span v-if="text === 1">男</span>
        <span v-else>女</span>
      </template>

      <!-- 操作 -->
      <template #action="text, record" style="height: 60px;">
        <router-link :to="`/user-manage/userManage/userDetail?id=${record.userId}`">
          详情
        </router-link>
        &nbsp;&nbsp;
        <a @click="audit(record)" v-if="record.state == 1">禁用</a>
        <a @click="audit(record)" v-else>正常</a>
        &nbsp;&nbsp;
        <a @click="$router.push({path: '/user-manage/oneself-audit/index'})">实名认证</a>
        &nbsp;&nbsp;
        <a @click="$router.push({path: '/user-manage/oneself-audit/index'})">个人认证</a>
      </template>
    </a-table>

    <!-- 禁用/正常 弹窗-->
    <StatusModal
      :visible ="modal.statusVisible"
      :type ="type"
      @confirmStatus ="confirmStatus"
      @cancelStatus ="modal.statusVisible = false"
    />

  </ak-container>
</template>

<script>
import * as UserApi from '@/api/user/user.js'
import StatusModal from '@/components/StatusModal'

const columns = [{
  title: '头像',
  dataIndex: 'headImage',
  width: 120,
  scopedSlots: {customRender: 'headImage'}
}, {
  title: '用户昵称',
  dataIndex: 'nickname',
  width: 100
}, {
  title: '手机号',
  dataIndex: 'phone',
  scopedSlots: { customRender: 'phone' },
  align: 'center',
  width: '130px'
}, {
  title: '状态',
  dataIndex: 'state',
  scopedSlots: {customRender: 'state' },
  align: 'center',
  width: 80
}, {
  title: '性别',
  dataIndex: 'sex',
  scopedSlots: { customRender: 'sex' },
  width: 80
}, {
  title: '生日',
  dataIndex: 'birthday',
  width: 100
}, {
  title: 'Ta的兴趣',
  dataIndex: 'interest',
  width: 260
}, {
  title: '喜欢',
  dataIndex: 'likes',
  width: 80
}, {
  title: '收藏',
  dataIndex: 'collection',
  width: 80
}, {
  title: '关注',
  dataIndex: 'follow',
  width: 80
}, {
  title: '最后IP属地',
  dataIndex: 'ipTerritory',
  width: 120
}, {
  title: '最后登录时间',
  dataIndex: 'lastLoginTime',
  defaultSortOrder: 'descend', // 默认上到下为由大到小的顺序
  sorter: (a, b) => { return a.lastLoginTime > b.lastLoginTime ? 1 : -1 },
  width: 120
}, {
  title: '注册时间',
  dataIndex: 'registerTime',
  defaultSortOrder: 'descend', // 默认上到下为由大到小的顺序
  sorter: (a, b) => { return a.registerTime > b.registerTime ? 1 : -1 },
  width: 200
}, {
  title: '操作',
  dataIndex: 'action',
  fixed: 'right',
  scopedSlots: { customRender: 'action' },
  width: 240,
}]

export default {
  components: { StatusModal },
  data () {
    return {
      pagination: {
        total: 0,
        pageSize: 10, // 每页中显示10条数据
        date: [],
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
      },
      loading: false,
      advanced: false,
      // 查询参数
      queryFilters: {
        page: 1,
        pageSize: 10,
        // phoneNumber: '',
        // nickname: '',
        // sex: null,
        // status: null,
        date: []
      },
      columns,
      // 行选择的 key 数组
      selectedRowKeys: [],
      // 行选择的完整数据
      selectedRows: [],
      // 行选择配置
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
            this.selectedRows = []
          }
        },
        rowSelection: {
          onChange: (selectedRowKeys, selectedRows) => {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
          }
        }
      },
      tableList: [],
      type: null,
      modal: {
        statusVisible: false
      },
      userId: null,
      loadingStatus: false
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    // 表格分页条件、筛选条件、排序条件发生变化后刷新表格数据
    handleTableChange(pagination, filters, sorter) {
      let that = this;
      that.sortedInfo = sorter;
      const pager = { ...that.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      that.pagination = pager;
      this.queryFilters.page = pagination.current;
      this.queryFilters.pageSize = pagination.pageSize;
      that.queryData()
    },
    // 加载数据方法
    queryData () {
      this.loading = true
      let _queryFilters = {}
      if (this.queryFilters.date.length >= 1) {
        this.queryFilters.registerStartTime = this.queryFilters.date[0]
        this.queryFilters.registerEndTime = this.queryFilters.date[1]
        _queryFilters = this.$utils.filterObjKeys(this.queryFilters, ['date'])
      } else {
        _queryFilters = this.$utils.filterObjKeys(this.queryFilters, ['registerStartTime', 'registerEndTime', 'date'])
      }
      UserApi.queryAllUser({..._queryFilters})
        .then((res) => {
          const { code, data } = res
          let row = []
          this.loading = false
          if (code === 0) {
            console.log('data', data)
            this.tableList = data.list
            this.pagination.total = data.total
          }
          return row
        })
        .catch(e => {
          this.message = e.message || '查询错误'
        })
        .finally(() => {
          this.loading = false
        })
    },
    search(){
      this.pagination.current=1;
      this.queryFilters.page = 1;
      this.queryData();
    },
    handleSex(val){
      console.log('va', val)
    },
    // 重置
    onResetForm () {
      this.$refs.form.resetFields()
      this.queryFilters = this.$options.data.call(this).queryFilters
      this.queryData()
    },
    audit(row) {
      this.type = row.state
      this.userId = row.id
      this.modal.statusVisible = true
    },
    confirmStatus() {
      const param = {
        id: this.userId,
        status: this.type == 1 ? 0 : 1
      }
      this.loadingStatus = true
      UserApi.updateUser(param)
        .then((res) => {
          console.log('res', res)
          const { code } = res
          console.log('code', code)
          this.loadingStatus = false
          if (code === 0) {
            this.$message.success('状态修改成功')
            this.modal.statusVisible = false
            this.queryData()
          }
        })
        .catch(e => {
          this.message = e.message || '查询错误'
        })
        .finally(() => {
          this.loading = false
        })
    },
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-card-body{
  padding: 18px;
}

/deep/ .ant-col-xl-8 {
  padding-left: 0 !important;
}
</style>
